.testimonials {
  // background-image: url('../../../../public/dotsBG.webp');
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  align-items: center;
  min-height: 450px;
  .clientInfo {
    display: flex;
    align-items: center;

    .clientImg {
      width: 50px;
      height: 50px;
      border: 2px solid #e9e9e9;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      margin-right: 15px;
      background-color: #f5f5f5;
    }

    .clintInfo {
      p {
        font-weight: 600;
        display: block;
        margin-bottom: 5px;
      }
    }
  }
  .button-style {
    padding: 17px 30px;
    font-weight: 600;
    border-radius: 100px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background: var(--gradient);
    color: #fff;
    svg {
      font-size: 20px;
    }
  }
  .heading-title {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 0px;
    line-height: 40px;
    color: rgb(0, 40, 86);
    position: relative;
  }
}
